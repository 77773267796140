/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
Note: any .css or .scss files included in the 'styles' directory
will be correctly compiled during `gulp serve` and `gulp`
 */

/* general */

#n-body a {
  text-decoration: none;
}

#n-body a:hover {
  text-decoration: underline !important;
}

div.mdl-layout__container {
  height: auto !important;
  background-color: #ffffff !important;
}

div#n-secondary-category-right {
  background-color: #ffffff !important;
}

div#n-body {
  background-color: #ffffff;
  z-index: 1;
}

/* utils */

.n-wrap {
  word-break: break-all !important;
  white-space: normal !important;
}

.n-no-padding {
  padding: 0 !important;
}

.n-no-padding-top {
  padding-top: 0 !important;
}

.n-min-width {
  min-width: 320px !important;
}

.n-max-width {
  max-width: 992px !important;
  margin: 0 auto !important;
}

.n-hide {
  display: none !important;
}

/* grid */

.mdl-grid {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mdl-grid .mdl-cell {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* list */

#n-songs {
  width: 100% !important;
  border: 0 !important;
}

#n-songs > tbody > tr.n-songs-no-background-row {
  background-color: #ffffff !important;
}

#n-songs > tbody > tr.n-songs-banned-row > td > p.n-songs-banned-text {
  display: block !important;
}

#n-songs > tbody > tr > td > p.n-songs-banned-text {
  display: none;
}

#n-songs > tbody > tr > td {
  border: 0 !important;
  margin: 0 !important;
  padding: 12px 0;
}

#n-songs > tbody > tr > td.n-songs-padding-right {
  padding-right: 16px !important;
}

#n-songs > tbody > tr > td.n-songs-padding-left {
  padding-left: 16px !important;
}

#n-songs > tbody > tr > td.n-songs-button-cell {
  width: 85px !important;
}

#n-songs > tbody > tr > td.n-songs-button-cell .n-songs-button-play {
  margin-right: 4px !important;
}

#n-songs > tbody > tr > td p {
  margin: 0 !important;
}

/* categories */

ul.n-category-list {
  margin: 0 !important;
  padding: 0 !important;
}

ul.n-category-list li {
  margin: 0 !important;
  padding: 0 !important;
}

ul.n-category-list li i.mdl-list__item-icon {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

/* song list caption */

#n-song-list-caption-row td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

#n-song-list-caption {
  font-size: 20px;
  margin-bottom: 0;
}

/* small widgets */

.n-logo img {
  height: 60px !important;
}

#n-recent-search {
  overflow: hidden !important;
}

.n-layout-padding {
  padding: 12px 16px !important;
}

#n-load-container td {
  text-align: center !important;
}

#n-query-container {
  width: 100% !important;
}

/* player */

#n-player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
}

.n-player-height {
  height: 124px !important;
}

/*@formatter:off*/

#n-player table {
  background-color: #f34434 !important;
  border: 0 !important;
  width: 100%;
}

#n-player table tbody tr {
  background-color: #f34434 !important;
}

/*@formatter:on*/

#n-player table tbody tr td {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}

#n-player table tbody tr td.n-player-button-cell {
  width: 64px;
}

/* ads */

#n-rek-top-banner {
  margin: 0 auto;
  overflow: hidden;
}

#n-rek-list-top-banner {
  margin: 0 auto;
  overflow: hidden;
}

#n-rek-list-middle-banner {
  margin: 0 auto;
  overflow: hidden;
}

/* feedback */

#show-feedback-dialog {
  text-transform: none;
}

#feedback-dialog {
  width: 600px;
  max-width: 95%;
}

#feedback-dialog textarea.error {
  border: 1px solid red;
}

.error-message {
  color: red;
}

.mdl-dialog .dialog__title .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.text-center {
  text-align: center;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
  text-align: right;
}

blockquote:before,
blockquote:after {
  content: '';
  display: none;
}

.mdl-layout__header-row {
  position: relative;
}

#menu-lower-right {
  color: #FFFFFF;
}

dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
